<template>
  <div style="height: inherit">

    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items d-flex align-center-center justify-content-between">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Prodcuts -->
    <section>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="suppliers"
        responsive
        :fields="fields"
        primary-key="id"
        show-empty
        empty-text="Este proveedor aún no está asignado a ninguna tienda"
      >

        <!-- Column: Supplier name data -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Raffle Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="(data.item.active_status === true) ? 'success' : 'warning'"
          >
            {{ data.item.active_status | status }}
          </b-badge>
        </template>

        <!-- Column: Raffle Date -->
        <template #cell(current_loan_balance)="data">
          $ {{ data.value }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            variant="success"
            size="sm"
            :to="{ name: 'store-supplier-payment', params: { store_supplier_id: data.item.id } }"
          >
            Pago a proveedor
          </b-button>
        </template>

      </b-table>
    </section>

  </div>
</template>

<script>
import _ from 'underscore'
import {
  BTable, BLink, BButton, BMedia, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BTable,
    BLink,
    BButton,
    BMedia,
    BBadge,
  },
  data() {
    return {
      searchQuery: null,
      fields: [
        {
          key: 'name',
          label: 'Proveedor',
        },
        {
          key: 'store_attributes.name',
          label: 'Tienda',
        },
        {
          key: 'current_loan_balance',
          label: 'Saldo deudor',
        },
        {
          key: 'status',
          label: 'Estado',
        },
        {
          key: 'actions',
          label: 'Herramientas',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('suppliers', ['suppliers']),
  },
  watch: {
    // eslint-disable-next-line
    searchQuery: _.debounce(function(query){
      this.fetchStoreSuppliers({
        by_name: query || null,
      })
    }, 500),
  },
  beforeMount() {
    this.fetchStoreSuppliers({ by_establishment_supplier: this.$route.params.establishment_supplier_id })
  },
  methods: {
    ...mapActions('suppliers', ['fetchStoreSuppliers']),
    clearSearchbarAndResetSearch() {
      this.searchQuery = null
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.grid-list {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr auto;

  .card-body {
    padding: 1.5rem 1rem;
    border-right: 1px solid #3b4253;
    display: flex;
    flex-direction: column;
  }

  .item-options {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
